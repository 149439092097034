import { lazy } from "react";

const subSectorRoute = {
  path: "/rwandan-markets-reports",
  component: lazy(() => import("view/RwandanMarkets")),
  title: "Rwandan Markets",
  protected: false,
};

export default subSectorRoute;
