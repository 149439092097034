import { lazy } from "react";

const reportsRoute = {
  path: "/reports",
  component: lazy(() => import("view/reports")),
  title: "Reports",
  protected: false,
};

export default reportsRoute;
